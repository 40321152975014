<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12 sm12>
        <v-card outlined class="primary--border">
          <v-card outlined class="">
            <v-card-title class="title">
              <u
                >Posting Summary for collection & generated
                invoice on SOD Statement {{transaction_date}}
                <span> ({{ getDateFormat(transaction_date) }})</span></u
              >

              <v-spacer></v-spacer>

              <v-download-column-select
                v-if="
                  (invoice_posting_summary.length > 0 ||
                    (receipt_posting_summary.data &&
                      receipt_posting_summary.data.length > 0)) &&
                  [1,2].includes(sod_status.is_eod)
                "
                :downloadColumns="downloadColumns"
                @onDownload="downloadNow"
                >Download Report</v-download-column-select
              >
            </v-card-title>
          </v-card>
          <div v-if="invoice_posting_summary.length > 0">
            <table class="table1" width="95%" v-if="accountServiceStatus">
              <thead>
                <tr>
                  <th
                    style="padding: 15px; background-color: #efefef;
                      font-size: 18px;
                    "
                    colspan="3"
                  >
                    &lt; Invoice Posting Summary &gt;
                  </th>
                </tr>
              </thead>
              <thead>
                <tr
                  style="background-color: rgba(239, 239, 239, 0.4)"
                  align="left"
                >
                  <th>Fee Head</th>
                  <th>Dr.</th>
                  <th>Cr.</th>
                </tr>
              </thead>

              <tbody>
                <tr
                  align="left"
                  v-for="(invoice, key) in invoice_posting_summary"
                  :key="key"
                >
                  <td>
                    {{ invoice.fee_head }}
                    <p style="font-size: 12px; color: #777; margin-bottom: 5px">
                      <span v-if="invoice.map_ledger_head"
                        >({{ invoice.map_ledger_head.asset_ledger }})</span
                      >
                      <span style="color: red" v-else
                        >No Mapping Record Found</span
                      >
                    </p>
                  </td>
                  <td>{{ invoice.total.currency() }}</td>
                  <td></td>
                </tr>
                <tr
                  align="left"
                  v-for="(invoice, key) in invoice_posting_summary"
                  :key="key + 100"
                >
                  <td>
                    {{ invoice.fee_head }}
                    <p style="font-size: 12px; color: #777; margin-bottom: 5px">
                      <span v-if="invoice.map_ledger_head"
                        >({{ invoice.map_ledger_head.income_ledger }})</span
                      ><span style="color: red" v-else>
                        No Mapping record Found</span
                      >
                    </p>
                  </td>
                  <td width="20%"></td>
                  <td width="20%">{{ invoice.total.currency() }}</td>
                </tr>

                <tr style="background-color: rgba(239, 239, 239, 0.4)">
                  <th>Grand Total:</th>
                  <th>{{ invoice_posting_summary_total.currency() }}</th>
                  <th>{{ invoice_posting_summary_total.currency() }}</th>
                </tr>
              </tbody>
            </table>
            <v-container
              v-if="
                accountServiceStatus &&
                sod_status.invoice_posting === 0 &&
                $auth.can('sod-sync-to-account') &&
                sod_status.invoice_posted_at === null &&
                previous_pending_invoice_sods.length === 0 &&
                sod_status.is_eod !== 0
              "
            >
              <p
                v-if="editSummaryInv === false"
                @click="editSummaryInv = true"
                class="pl-3"
                style="cursor: pointer"
              >
                Narration: &nbsp; <strong>{{ invoice_narration }}</strong>
                <span style="margin-left: 20px">
                  <v-icon color="primary" style="font-size: 18px"
                    >edit</v-icon
                  ></span
                >
              </p>
              <v-textarea
                style="font-weight: bold"
                v-if="editSummaryInv === true"
                outlined
                :error-messages="form.errors.get('invoice_narration')"
                v-model="invoice_narration"
                name="description"
                rows="3"
              ></v-textarea>

              <v-btn
                v-if="sod_status.invoice_posted_at === null"
                block
                large
                color="primary"
                @click="postInvoiceToAccount"
                :disabled="missing_map_head.length > 0 || !currentYear"
              >
                {{ 'Post to Account' }} (voucher entry date {{ today }})
              </v-btn>
            </v-container>
            <v-container
              v-else-if="
                sod_status.invoice_posting === 1 &&
                sod_status.invoice_posted_at !== null &&
                accountServiceStatus
              "
            >
              <alert-message
                :title="`Invoice Posted to Account (By ${sod_status.accountant})`"
                type="success"
                message="The above information has already been posted to account. Please visit your accouting section for more information"
              ></alert-message>
            </v-container>
            <v-container
              v-else-if="
                sod_status.is_eod === 0 &&
                $auth.can('sod-sync-to-account') &&
                accountServiceStatus
              "
            >
              <alert-message
                title="Not Ready"
                type="error"
                message="SOD cannot be posted to account on the same day it was created. Please come back tomorrow."
              ></alert-message>
            </v-container>
            <v-container
              v-else-if="
                previous_pending_invoice_sods.length > 0 && accountServiceStatus
              "
            >
              <alert-message type="error" :title="`Pending Previous Posting`">
                <template v-slot:message>
                  Posting for "{{ transaction_date }}" is disabled because you
                  have previous sod's pending for posting. <br />
                  Switch SOD
                  <v-menu
                    min-width="200"
                    max-height="300"
                    offset-y
                    transition="slide-y-reverse-transition"
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn text v-on="on"
                        >{{ transaction_date }}
                        <v-icon ml-2> arrow_drop_down </v-icon>
                      </v-btn>
                    </template>
                    <v-list v-if="previous_pending_invoice_sods.length > 0">
                      <v-subheader>Previous Pending SOD</v-subheader>
                      <span
                        v-for="(item, index) in previous_pending_invoice_sods"
                        :key="index"
                      >
                        <v-list-item @click="viewStatement(item.sod_date)">
                          <v-list-item-title>{{
                            item.sod_date
                          }}</v-list-item-title>
                        </v-list-item>
                      </span>
                    </v-list>
                  </v-menu>
                </template>
              </alert-message>
            </v-container>
            <br />
          </div>

          <hr v-if="invoice_posting_summary.length" />

          <div
            v-if="
              receipt_posting_summary.data &&
              receipt_posting_summary.data.length > 0 &&
              accountServiceStatus
            "
          >
            <br />
            <table class="table1" width="95%">
              <thead>
                <tr>
                  <th
                    style="
                      padding: 15px;
                      background-color: #efefef;
                      font-size: 18px;
                    "
                    colspan="3"
                  >
                    &lt; Collection Posting Summary &gt;
                  </th>
                </tr>
              </thead>
              <thead>
                <tr
                  style="background-color: rgba(239, 239, 239, 0.4)"
                  align="left"
                >
                  <th>Fee Head</th>
                  <th>Dr</th>
                  <th>Cr</th>
                </tr>
              </thead>

              <tbody>
                <tr align="left" v-if="cash_cheque.cash">
                  <td>
                    Cash
                    <p style="font-size: 12px; color: #777; margin-bottom: 5px">
                      (Cash Account)
                    </p>
                  </td>
                  <td width="20%">{{ cash_cheque.cash.currency() }}</td>
                  <td width="20%"></td>
                </tr>
                <tr align="left" v-if="cash_cheque.cheque > 0">
                  <td>
                    Cheque
                    <p style="font-size: 12px; color: #777; margin-bottom: 5px">
                      <!-- (Cheque Account) -->
                      <span
                        v-if="!receipt_posting_summary.cheque_mapping"
                        style="color: red"
                      >
                        No mapping record Found
                      </span>
                      <span
                        v-else-if="
                          receipt_posting_summary.cheque_mapping
                            .ledger_head_asset
                        "
                      >
                        {{
                          receipt_posting_summary.cheque_mapping
                            .ledger_head_asset.name
                        }}
                      </span>
                      <span
                        v-else-if="
                          receipt_posting_summary.cheque_mapping
                            .ledger_head_income
                        "
                      >
                        {{
                          receipt_posting_summary.cheque_mapping
                            .ledger_head_income.name
                        }}
                      </span>
                    </p>
                  </td>
                  <td>
                    {{ cash_cheque.cheque.currency() }}
                  </td>
                  <td></td>
                </tr>

                <tr
                  align="left"
                  v-for="(gtp, key) in gateway_payment"
                  :key="key"
                >
                  <td>
                    {{ gtp.payment_mode.toUpperCase() }}
                    <p style="font-size: 12px; color: #777; margin-bottom: 5px">
                      ({{ gtp.payment_mode }} Account)
                    </p>
                  </td>
                  <td>
                    {{ gtp.amount.currency() }}
                  </td>
                  <td></td>
                </tr>
                <tr align="left" v-if="payment_via_advance.amount > 0">
                  <td>
                    Advance
                    <p style="font-size: 12px; color: #777; margin-bottom: 5px">
                      (Advance Account)
                    </p>
                  </td>
                  <td>
                    {{ payment_via_advance.amount.currency() }}
                  </td>
                  <td></td>
                </tr>
                <tr align="left" v-if="payment_via_security.amount > 0">
                  <td>
                    Security
                    <p style="font-size: 12px; color: #777; margin-bottom: 5px">
                      (Security Account)
                    </p>
                  </td>
                  <td>
                    {{ payment_via_security.amount.currency() }}
                  </td>
                  <td></td>
                </tr>
                <tr align="left" v-if="cash_cheque.instant_discount > 0 || payment_via_online.instant_discount > 0">
                  <td align="left">
                    Instant Disount
                    <p style="font-size: 12px; color: #777; margin-bottom: 5px">
                      (Discount Account)
                    </p>
                  </td>
                  <td>{{ (cash_cheque.instant_discount + payment_via_online.instant_discount).currency() }}</td>
                  <td></td>
                </tr>
                
                <template v-for="(y, j) in itemData">
                  <tr
                    align="left"
                    :key="chequeCash.length + j"
                    v-if="y.fee_head"
                  >
                    <td>
                      {{ y.fee_head }}
                      <p
                        style="font-size: 12px; color: #777; margin-bottom: 5px"
                        v-if="y.map_ledger_head"
                      >
                        <span v-if="y.map_ledger_head.asset_ledger">
                          ({{ y.map_ledger_head.asset_ledger }})
                        </span>
                        <span v-else-if="y.map_ledger_head.income_ledger">
                          ({{ y.map_ledger_head.income_ledger }})
                        </span>
                        <span v-else style="color: red">
                          No mapping record Found
                        </span>
                      </p>
                      <p
                        v-else-if="y.fee_head === 'Previous Due'"
                        style="color: green; margin-bottom: 5px"
                      >
                        Previous Due Receivable (Default)
                      </p>

                      <p
                        v-else-if="y.fee_head === 'Advance'"
                        style="color: green; margin-bottom: 5px"
                      >
                        Advance (Default)
                      </p>
                      <p
                        v-else-if="y.fee_head === 'Security Deposit'"
                        style="color: green; margin-bottom: 5px"
                      >
                        Security Deposit (Default)
                      </p>
                      <p v-else-if="y.fee_head === 'Penalty'">
                        {{ y }}
                      </p>
                      <p v-else style="color: red; margin-bottom: 5px">
                        <span style="display: none">{{
                          (syncButton = true)
                        }}</span>
                        No mapping record Found <br />{{ y }}
                      </p>
                    </td>
                    <td></td>
                    <td>
                      {{ (y.sum_amount + y.sum_instant_discount).currency() }}
                    </td>
                  </tr>
                </template>
                <tr
                  style="background-color: rgba(239, 239, 239, 0.4)"
                  align="left"
                >
                  <th align="left">Grand Total :</th>
                  <th>
                    {{
                      (
                        cash_cheque.cash_cheque +
                        cash_cheque.instant_discount +
                        payment_via_advance.amount +
                        payment_via_online.amount + 
                        payment_via_online.instant_discount +
                        payment_via_security.amount
                      ).currency()
                    }}
                  </th>
                  <th>
                    {{
                      (
                        parseFloat(totalSum) +
                        parseFloat(sum_fee_head_wise_instant_discount)
                      ).currency()
                    }}
                  </th>
                  <p
                    style="display: none"
                    v-if="
                      cash_cheque.cash_cheque + cash_cheque.instant_discount !==
                      totalSum + sum_fee_head_wise_instant_discount
                    "
                  >
                    {{ (syncButton = true) }}
                  </p>
                </tr>
              </tbody>
            </table>
            <!-- Action -->

            <v-container
              style="width: 100%"
              v-if="
                accountServiceStatus &&
                sync === 1 &&
                form.items.data.length > 0 &&
                $auth.can('sod-sync-to-account') &&
                sod_status.account_posted_at === null &&
                totalDr === totalCr &&
                previous_pending_sods.length === 0 &&
                sod_status.is_eod === 1
              "
            >
              <p
                v-if="editSummaryRec === false"
                @click="editSummaryRec = true"
                class="pl-2"
                style="cursor: pointer"
              >
                Narration: &nbsp; <strong>{{ form.narration }}</strong>
                <span style="margin-left: 20px"
                  ><v-icon color="primary" style="font-size: 18px"
                    >edit</v-icon
                  ></span
                >
              </p>
              <v-textarea
                style="font-weight: bold"
                v-if="editSummaryRec === true"
                outlined
                :error-messages="form.errors.get('narration')"
                v-model="form.narration"
                name="description"
                rows="3"
              ></v-textarea>

              <br />
              <v-btn block large color="primary" @click="postToAccount">
                {{ 'Post Receipt to Account' }}
              </v-btn>
            </v-container>
            <v-container
              v-else-if="
                sod_status.is_eod === 2 &&
                sod_status.account_posted_at !== null &&
                accountServiceStatus
              "
            >
              <alert-message
                :title="`Collection Posted to Account (By ${sod_status.accountant})`"
                type="success"
                message="The above information has already been posted to account. Please visit your accouting section for more information"
              ></alert-message>
            </v-container>
            <v-container
              v-else-if="
                sod_status.is_eod === 0 &&
                $auth.can('sod-sync-to-account') &&
                accountServiceStatus
              "
            >
              <alert-message
                title="Not Ready"
                type="error"
                message="SOD cannot be posted to account on the same day it was created. Please come back tomorrow."
              ></alert-message>
            </v-container>
            <v-container
              v-else-if="
                totalDr.toFixed(2) !== totalCr.toFixed(2) &&
                $auth.can('sod-sync-to-account') &&
                accountServiceStatus
              "
            >
              <alert-message
                type="error"
                :title="`Dr. (${totalDr.currency()}) / Cr. (${totalCr.currency()}) Mismatch`"
                message="Looks like the Dr. and Cr. does not tally with each other and hence account posting is disabled please check all the transactions carefully and try again. If you're still having problem please contact system administration"
              ></alert-message>
            </v-container>
            <v-container
              v-else-if="
                totalDr === totalCr &&
                previous_pending_sods.length > 0 &&
                accountServiceStatus
              "
            >
              <alert-message type="error" :title="`Pending Previous Posting`">
                <template v-slot:message>
                  Posting for "{{ transaction_date }}" is disabled because you
                  have previous sod's pending for posting. <br />
                  Switch SOD
                  <v-menu
                    min-width="200"
                    max-height="300"
                    offset-y
                    transition="slide-y-reverse-transition"
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn text v-on="on"
                        >{{ transaction_date }}
                        <v-icon ml-2> arrow_drop_down </v-icon>
                      </v-btn>
                    </template>
                    <v-list v-if="previous_pending_sods.length > 0">
                      <v-subheader>Previous Pending SOD</v-subheader>
                      <span
                        v-for="(item, index) in previous_pending_sods"
                        :key="index"
                      >
                        <v-list-item @click="viewStatement(item.sod_date)">
                          <v-list-item-title>{{
                            item.sod_date
                          }}</v-list-item-title>
                        </v-list-item>
                      </span>
                    </v-list>
                  </v-menu>
                </template>
              </alert-message>
            </v-container>
            <br />

            <hr />
          </div>

          <v-data-table
            v-if="!accountServiceStatus"
            :headers="headers"
            :items="form.items.data"
            :search="search"
            footer-props.items-per-page-options="rowsPerPageItems"
            :options.sync="pagination"
            :server-items-length="form.items.meta.total"
            :loading="form.loading"
          >
            <template v-slot:item="{ index, item }">
              <tr
                @click="props.expanded = !props.expanded"
                style="cursor: pointer"
              >
                <td>{{ index + form.items.meta.from }}</td>
                <td class="text-xs-left">
                  {{ item.fee_head }}
                </td>
                <td class="text-xs-left">
                  {{ item.sum_amount.currency() }}
                </td>
              </tr>
            </template>
            <template v-slot:body.append>
              <td :colspan="headers.length - 1">
                <strong>Total:</strong>
              </td>
              <td>{{ totalSum.currency() }}</td>
            </template>
          </v-data-table>

          <v-container
            v-if="
              receipt_posting_summary.data &&
              receipt_posting_summary.data.length === 0 &&
              invoice_posting_summary &&
              invoice_posting_summary.length === 0
            "
          >
            <alert-message
              title="No Records Found"
              type="error"
              message="Looks like you do not have any transactions or invoices generate for the given EOD date"
            ></alert-message>
          </v-container>
        </v-card>
      </v-flex>
      <v-dialog v-model="dialog" persistent max-width="290">
        <v-card>
          <v-card-title class="title pa-3 warning white--text">
            <v-icon class="mr-2 white--text">warning</v-icon>
            Please, wait!
          </v-card-title>
          <v-card-text class="ma-0">
            <br />
            Are you sure you want to post this data to account?</v-card-text
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="success"
              outlined
              small
              @click="(dialog = false), $emit('disagree')"
              >Cancel</v-btn
            >
            <v-btn color="warning" outlined small @click="save">Post Now</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="invoicePostingDialog" persistent max-width="500">
        <v-card>
          <v-card-title class="title pa-3 warning white--text">
            <v-icon class="mr-2 white--text">warning</v-icon>
            Please, wait!
          </v-card-title>
          <v-card-text class="ma-0">
            <br />
            <alert-message
              extraSmall
              type="error"
              title="Alert!! Please be cautios"
            >
              <template v-slot:message>
                <p style="font-size: 14px">
                  <strong style="color: red">
                    Invoice once posted to account cannot be rolledback,
                  </strong>
                  therefore please make sure the invoice generated was all
                  correct.
                </p>
              </template>
            </alert-message>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="success"
              outlined
              small
              @click="(invoicePostingDialog = false), $emit('disagree')"
              >Cancel</v-btn
            >
            <v-btn
              color="warning"
              outlined
              small
              @click="postInvoiceToAccountAction"
              >Post Now</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="reasonDialog" persistent max-width="290">
        <v-card>
          <v-card-title class="title pa-3">
            <v-textarea
              outlined
              name="input-7-4"
              label="Narration"
              v-model="form.narration"
            ></v-textarea>
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" outlined small @click="reasonDialog = false"
              >Cancel</v-btn
            >
            <v-btn
              :disabled="!form.narration"
              color="warning"
              outlined
              small
              @click="save"
              >Post</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-overlay :value="pageLoader">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-overlay :value="fetchingRecords">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>
<script>
import { mapState } from 'vuex';
import Form from '@/library/Form';
import { redirectDownloadUrl } from '../../../../library/helpers';

const NepaliDate = require('nepali-date');
export const nd = new NepaliDate();

export default {
  data: () => ({
    downloadColumns: [],
    today: nd.format('YYYY-MM-DD'),
    pageLoader: false,
    fetchingRecords: false,
    form: new Form(
      {
        sod_date: '0000-00-00',
        narration: '',
        invoice_narration: '',
        eod_status: '',
      },
      '/api/eod-collection'
    ),
    invoice_narration: '',
    editSummaryRec: false,
    editSummaryInv: false,
    search: null,
    sync: 0,
    transaction_date: '',
    dialog: false,
    invoicePostingDialog: false,
    reasonDialog: false,
    totalSum: 0,
    totalDr: 0,
    totalCr: 0,
    accountServiceStatus: false,
    pagination: {
      rowsPerPage: 10,
      sortBy: ['sod_date'],
    },
    cashCheque: [],
    cash_cheque: {
      cash_instant_discount: 0,
      cheque_instant_discount: 0,
      instant_discount: 0,
      cash: 0,
      cheque: 0,
      cash_cheque: 0,
    },
    invoice_posting_summary: [],
    receipt_posting_summary: {},
    invoice_posting_summary_total: 0,
    payment_via_advance: {
      amount: 0,
      instant_discount: 0,
    },
    payment_via_security: {
      amount: 0,
      instant_discount: 0,
    },
    gateway_payment: [],
    sum_fee_head_wise_instant_discount: 0,
    itemData: [],
    headers: [
      { text: '#', align: 'center', value: 'id', width: 50, sortable: false },
      { text: 'Fee Head', align: 'left', value: 'fee_head' },
      { text: 'Amount', align: 'left', value: 'amount' },
    ],
    syncButton: false,
    sod_status: {},
    missing_mapping_records: [],
    previous_pending_sods: [],
    previous_pending_invoice_sods: [],
    download_url: '',
    download_url_invoice: '',
    currentYear: false,
  }),

  computed: {
    ...mapState(['batch']),
    missing_map_head() {
      return [...new Set(this.missing_mapping_records)];
    },
  },
  mounted() {
    this.get();
    this.transaction_date = this.$route.params.sod;

    this.accountServiceStatus = true;

    this.fetchInvoicePostingSummary();
    this.getFiscalYear();
  },

  methods: {
    queryString() {
      let json = this.pagination;
      let accountServiceStatus = !!this.$auth.getServices('ACCOUNT');

      return (
        '?' +
        Object.keys(json)
          .map(function (key) {
            if (![null, undefined].includes(json[key]))
              return (
                encodeURIComponent(key) + '=' + encodeURIComponent(json[key])
              );
          })
          .join('&') +
        '&transactionDate=' +
        this.$route.params.sod +
        '&reporting_type=sod-posting' +
        '&accountService=' +
        accountServiceStatus
      );
    },
    downloadNow({ type, columns, columnString }) {
      let accountServiceStatus = !!this.$auth.getServices('ACCOUNT');

      if (columnString.split(',').includes('collection')) {
        redirectDownloadUrl({uri:'/download/billing/sod-feehead',queryString:`transactionDate=${this.transaction_date}&type=${type}&accountService=${accountServiceStatus}`})
      }

      if (columnString.split(',').includes('invoice')) {
        redirectDownloadUrl({uri:'/download/billing/sod-invoice',queryString:`transactionDate=${this.transaction_date}&type=${type}&accountService=${accountServiceStatus}`})
      }
    },
    fetchInvoicePostingSummary() {
      this.fetchingRecords = true;
      this.$rest
        .get('/api/invoice/posting-summary?reporting_type=sod-posting', {
          params: { sod_date: this.$route.params.sod },
        })
        .then(({ data }) => {
          this.download_url_invoice = data.download_link;
          if (data.data.length > 0) {
            this.downloadColumns.push({
              value: 'invoice',
              name: 'Invoice Summary',
              selected: false,
            });
          }
          data.data.map((item) => {
            if (!item.map_ledger_head) {
              this.missing_mapping_records.push(item.fee_head);
            }
          });
          this.invoice_posting_summary_total = 0;
          this.invoice_posting_summary = data.data.map((item) => {
            this.invoice_posting_summary_total += item.total;
            return item;
          });
          this.invoice_narration = data.narration;
        })
        .finally(() => {
          this.fetchingRecords = false;
        });
    },

    get(params) {
      this.form
        .get(null, this.queryString())
        .then(({ data }) => {
          if (data.data.length > 0) {
            this.downloadColumns.push({
              value: 'collection',
              name: 'Collection Summary',
              selected: true,
            });
          }

          this.download_url = data.download_url;
          data.data.map((item) => {
            if (
              !item.map_ledger_head &&
              item.fee_head !== 'Advance' &&
              item.fee_head !== 'Previous Due' &&
              item.fee_head !== 'Penalty'
            ) {
              this.missing_mapping_records.push(item.fee_head);
            }
          });
          this.previous_pending_sods = data.pending_sods;
          this.previous_pending_invoice_sods = data.pending_invoice_sods;

          this.sod_status = data.sod_status;
          this.chequeCash = data.cash_cheque;
          this.receipt_posting_summary = data;
          this.cash_cheque = {
            cash_instant_discount: 0,
            cheque_instant_discount: 0,
            instant_discount: 0,
            cash: 0,
            cheque: 0,
            cash_cheque: 0,
          };
          this.payment_via_advance = {
            amount: 0,
            instant_discount: 0,
          };
          this.payment_via_security = {
            amount: 0,
            instant_discount: 0,
          };
          this.payment_via_online = {
            amount: 0,
            instant_discount: 0,
          };

          data.cash_cheque.map((res) => {
            if(res.payment_mode !== 'esewa-qr') {
              this.cash_cheque.instant_discount += parseFloat(
                res.instant_discount
              );
              this.cash_cheque.cash_cheque += parseFloat(res.amount);
              if (res.payment_mode === 'cash') {
                this.cash_cheque.cash += parseFloat(res.amount);
                this.cash_cheque.cash_instant_discount += parseFloat(
                  res.instant_discount
                );
              }
              if (res.payment_mode === 'cheque') {
                this.cash_cheque.cheque += parseFloat(res.amount);
                this.cash_cheque.cheque_instant_discount += parseFloat(
                  res.instant_discount
                );
              }
            }
          });
          data.advance_payment.map((advance) => {
            this.payment_via_advance.amount += advance.amount;
            this.payment_via_advance.instant_discount +=
              advance.instant_discount;
          });

          data.security_payment.map((security) => {
            this.payment_via_security.amount += security.amount;
            this.payment_via_security.instant_discount +=
              security.instant_discount;
          });

          //the khalti_payment is just a name actually it contains all the online gateway payment.
          this.gateway_payment = data.online_gateway.map((gtp) => {
            this.payment_via_online.amount += gtp.amount;
            this.payment_via_online.instant_discount += gtp.instant_discount;
            return gtp;
          });

          let result = data.data;
          this.sum_fee_head_wise_instant_discount = 0;
          let sumAdvance = 0;
          let sumSecurity = 0;
          this.itemData = [];
          let resultData = {};
          result.map((res) => {
            if (res.fee_head === 'Advance') {
              sumAdvance += parseFloat(res.sum_amount);
              resultData = {
                fee_head_id: res.fee_head_id,
                fee_head: res.fee_head,
                sum_amount: sumAdvance,
                sum_instant_discount: parseFloat(res.sum_instant_discount),
                map_ledger_head: res.map_ledger_head,
              };
            } else if (res.fee_head === 'Security Deposit') {
              sumSecurity += parseFloat(res.sum_amount);
              resultData = {
                fee_head_id: res.fee_head_id,
                fee_head: res.fee_head,
                sum_amount: sumSecurity,
                sum_instant_discount: parseFloat(res.sum_instant_discount),
                map_ledger_head: res.map_ledger_head,
              };
            } else {
              this.itemData.push({
                fee_head_id: res.fee_head_id,
                fee_head: res.fee_head,
                sum_amount: res.sum_amount,
                sum_instant_discount: parseFloat(res.sum_instant_discount),
                map_ledger_head: res.map_ledger_head,
              });
            }
          });
          this.itemData.push(resultData);
          this.itemData.map((res) => {
            if (res.fee_head) {
              this.sum_fee_head_wise_instant_discount += parseFloat(
                res.sum_instant_discount
              );
            }
          });
          this.sync = data.isEod;
          this.form.narration =
            'Account Post Receipt from ' +
            data.narration.from_bill_no +
            ' to ' +
            data.narration.to_bill_no +
            ' and SOD date ' +
            this.transaction_date +
            '.';
          let sumAmount = 0;
          let crSide = 0;
          let drSide = 0;

          data.data.map(function (res) {
            drSide +=
              parseFloat(res.sum_amount) + parseFloat(res.sum_instant_discount);
            sumAmount += parseFloat(res.sum_amount);
          });

          data.advance_payment.map((item) => {
            crSide += item.amount + item.instant_discount;
          });
          data.security_payment.map((item) => {
            crSide += item.amount + item.instant_discount;
          });
          data.online_gateway.map((item) => {
            crSide += item.amount + item.instant_discount;
          });

          data.cash_cheque.map((item) => {
            if(item.payment_mode !== 'esewa-qr') {
              crSide += item.amount + item.instant_discount;
            }
          });

          this.totalSum = sumAmount;

          this.totalDr = Math.round(drSide * 100) / 100;
          this.totalCr = Math.round(crSide * 100) / 100;
        })
        .finally(() => {
          this.fetchingRecords = false;
        });
    },
    modalReason() {
      this.reasonDialog = true;
      this.dialog = false;
    },

    save() {
      let assetsLedger = [];
      this.itemData.map((res) => {
        if (res.fee_head) {
          assetsLedger.push({
            assets_ledger_head_id: res.map_ledger_head
              ? res.map_ledger_head.asset_ledger_head_id
              : null,
            amount:
              parseFloat(res.sum_amount) + parseFloat(res.sum_instant_discount),
            fee_head: res.fee_head,
          });
        }
      });

      if (this.accountServiceStatus) {
        this.pageLoader = true;
        this.$rest
          .post('api/eod-sync', {
            assetsLedger: assetsLedger,
            gateway_payment: this.gateway_payment,
            cash_cheque: this.cash_cheque,
            cheque_mapping: this.receipt_posting_summary.cheque_mapping,
            advance_payment: this.payment_via_advance,
            security_payment: this.payment_via_security,
            sod_date: this.$route.params.sod,
            entry_date: this.$route.params.sod, //nd.format("YYYY-MM-DD"),
            narration: this.form.narration,
            online_gateway_payment: this.payment_via_online,
          })
          .then(({ data }) => {
            this.dialog = false;
            this.get();
            this.$events.fire('notification', {
              message: data.message,
              status: 'success',
            });
          })
          .catch((e) => {
            this.$events.fire('notification', {
              message: e.response.data.message,
              status: 'error',
            });
            this.dialog = false;
          })
          .finally(() => {
            this.pageLoader = false;
          });
      }
    },
    viewStatement(sod_date) {
      this.$router.push({
        name: 'sod-statement',
        params: {
          sod: sod_date,
        },
      });
    },

    postInvoiceToAccount() {
      if (
        (!this.invoice_narration || this.invoice_narration.length < 5) &&
        this.accountServiceStatus
      ) {
        this.$events.fire('notification', {
          message: 'Please fill the narration.',
          status: 'error',
        });
      } else this.invoicePostingDialog = true;
    },
    postInvoiceToAccountAction() {
      if (this.accountServiceStatus) {
        this.pageLoader = true;

        //account service enabled
        this.$rest
          .post('api/eod-sync-invoice', {
            cash_cheque: this.cash_cheque,
            advance_payment: this.payment_via_advance,
            sod_date: this.$route.params.sod,
            entry_date: this.$route.params.sod, //nd.format("YYYY-MM-DD"),
            narration: this.form.narration,
            // khalti_payment: this.payment_via_online,
            invoice_posting_summary: this.invoice_posting_summary,
            invoice_narration: this.invoice_narration,
          })
          .then(({ data }) => {
            this.invoicePostingDialog = false;
            this.get();
            this.$events.fire('notification', {
              message: data.message,
              status: 'success',
            });
          })
          .catch((e) => {
            this.$events.fire('notification', {
              message: e.response.data.message,
              status: 'error',
            });
            this.invoicePostingDialog = false;
          })
          .finally(() => {
            this.pageLoader = false;
          });
      }
    },
    postToAccount() {
      if (!this.form.narration && this.accountServiceStatus) {
        this.$events.fire('notification', {
          message: 'Please fill the narration.',
          status: 'error',
        });
      } else this.dialog = true;
    },
    getDateFormat(date) {
      try {
        let dateInstance = new NepaliDate(date.dateForm());
        return dateInstance.format('MMMM D, YYYY DDDD');
      } catch (e) {
        return '-';
      }
    },
    getFiscalYear() {
      let years = JSON.parse(window.localStorage.getItem('fiscalyear'));
      this.currentYear = years.is_current;
    },
  },
};
</script>
<style lang="scss">
.table1 {
  border-collapse: collapse;
  margin: 17px;
}

table.table1 td {
  border: 1px solid #cccccc;
  padding: 5px;
  padding-left: 15px;
}

table.table1 th {
  border: 1px solid #cccccc;
  padding: 5px;
  padding-left: 15px;
}
.alert-message .v-btn {
  padding: 0 !important;
}

.switcher_tab_fixed {
  position: fixed;
  right: 30px;
  bottom: 20px;
  height: 50px;
  width: 200px;
  background: red;
  z-index: 2;
}
</style>
